#pong-container {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 1px black;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

canvas {
    background-color: transparent;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
}

#border-line {
    border:none;
    border-top: 2px dashed black;
    background: transparent !important;
    bottom: 169px; 
    position: absolute; 
    width: 100%;
    height:1px;
}

#pong-menu, #pong-ui {
    bottom: 0; 
    left: 0; 
    position: absolute; 
    width: 100%;
}

#pong-menu {
    height: 65%;
	transition: all 0.25s ease-in-out;
}

#pong-ui {
    display: none;
    height: 100%;
}

#pong-ui-cont {
    display: flex;
    flex-direction: column;
    margin-top: 152px;
}

#upper-ui, #lower-ui {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #c4eee867;
}

.ui-button:hover {
    color: #ffffff94;
    cursor: pointer;
}

#play-button {
    padding: 0;
    border: none;
    background: none;
    background-color: #afb9c593;
    color: #c4eee8;
    margin: 0;
    padding: 4pt 10pt;
    -webkit-box-shadow: 0 0 3px rgb(78, 78, 78);
    box-shadow: 0 0 3px rgb(78, 78, 78);
	transition: all 0.25s ease-in-out;
}
#play-button:hover {
    background-color: #555a5f;
    color: white;
	transition: all 0.25s ease-in-out;
    cursor: pointer;
}

#menu-instr, #pong-menu p {
    margin-top: 40px;
    color: #c4eee867;
}