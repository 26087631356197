.full-profile-container {
    height: 100%;
    position: relative;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.mid {
    height: 100%;
    width: 100%;
}
.profile-pic {
    border: 2px solid #2a3c47dd;
    border-radius: 50%;
    /* padding-top: 4vh; uncomment to make it a cool v shape lmao */
    max-width: 35%;
    max-height: 35%;
}
.profile-container h1 {
    padding: 0;
    margin: 0;
}
.profile-container {
    height: fit-content;
}
.miniGame {
    padding-top: 5%;
    padding-bottom: 10%;
    height: 60%;
}
.contact-info-container {
    height: fit-content;
    margin: 0;
    padding: 0;
}
.links-info p {
    padding: 0;
    margin: 0;
}