.game-page-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: fit-content;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}
.game-page-container h1 {
    margin-bottom: 0.5em;
}

.g-languages {
    margin: 0 auto !important;
}

.game-box p {
    margin-top: 0 !important;
}

.game-box h3 {
    margin-bottom: 0 !important;
}

.game-box {
    height: auto;
    min-width: 100%;
    max-width: 100%;
    line-height: 0;
    margin: 0;
    padding: 0;
    -webkit-column-count: 2;
    -webkit-column-gap:   0.5em;
    -moz-column-count:    2;
    -moz-column-gap:      0.5em;
    column-count:         2;
    column-gap:           0.5em;  
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.g-info-container {
    position: relative;
    line-height: normal;
    height: auto;
    width: 100%;
    margin-bottom: 0.5em;
    page-break-inside: avoid;
    border-radius: 8px;
}
.g-tint {
    background-color: #15151a6b;
    padding: 1em;
    height: 100%;
    color: transparent;
	transition: all 0.5s ease-in-out;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.g-tint p {
    margin-top: 2em;
    margin-bottom: 2em;
}
.g-tint h3 {
    padding: 0;
    margin: 0;
    color: #c4eee8;
}
.g-tint:hover {
    background-color: #15151aaf;
    color: #c4eee8;
	transition: all 0.5s ease-in-out;
}
.g-links {
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
    flex-wrap: wrap;
}
.g-links a {
    background-color: #afb9c593;
    color: #99ffec;
    border-radius: 4px;
    margin: 0;
    padding: 2pt 10pt;
    -webkit-box-shadow: 0 0 2pt rgb(78, 78, 78);
    box-shadow: 0 0 2pt rgb(78, 78, 78);
	transition: all 0.25s ease-in-out;
}
.g-links a:hover {
    background-color: #555a5f;
    color: white;
	transition: all 0.25s ease-in-out;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media screen and (max-width: 600px) {
    .game-page-container {
        -webkit-animation: fadein 0.75s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.75s; /* Firefox < 16 */
         -ms-animation: fadein 0.75s; /* Internet Explorer */
          -o-animation: fadein 0.75s; /* Opera < 12.1 */
             animation: fadein 0.75s;
        }
    .game-box {
        -webkit-column-count: 1;
        -moz-column-count:    1;
        column-count:         1;  
    }
    .g-tint {
        background-color: #15151a8e;
        color: #c4eee8;
    }
}