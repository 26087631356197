.main-container{
  overflow: hidden;
  /* background-image: linear-gradient(60deg, #15151ae8, #192c38e1); */
  background-image: linear-gradient(60deg, #3a3a47b7, #192c38b2);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 85vh;
  padding: 4em;
}

.lbar-container {
  background-color: #afb9c5d2;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 3%;
  height: 77vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  color: white;
  box-shadow: 0 0 2pt black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* 68B9AC 058EA0 8FB3BE afb9c5*/
.icon-container {
  text-align: center;
}

.lbar-icon {
  width: 80%;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.lbar-icon:hover {
  cursor: pointer;
  fill: white;
}

.mid-container {
  position: relative;
  border-radius: 12px;
  /* background-color: #3e525e; */
  background-image: linear-gradient(75deg, #2f3d46bb, #506775bb);
  width: 20vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 0 4pt black;
}

.rbox-container {
  /* background-color: #3e525e; */
  background-image: linear-gradient(75deg, #3e525edd, #507388dd);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding: 2vh;
  width: 33vw;
  height: 77vh;
  box-shadow: 0 0 2pt black;
}

.wrapper:hover .right-container {
  transition: 1s;
  left: 0;
}

/* On screens that are 992px or less, tablets/small laptops */
@media screen and (max-width: 1024px) {
  .main {
    padding: 2vh;
    height: 95vh;
  }
  .lbar-container {
    margin: 0;
    width: 10vw;
    height: 90vh;
  }
  .mid-container {
    display: none;
  }
  .rbox-container {
    margin: 0;
    width: 90vw;
    height: 90vh;
  }
}

/* On screens that are 600px or less, mobile */
@media screen and (max-width: 600px) {
  .main {
    height: 95vh;
  }
  .rbox-container {
    width: 90vw;
    height: 90vh;
    border-radius: 4px;
    overflow-x: hidden;
  }
}

/* Big screens */

@media screen and (min-width: 1700) {
  .main {
    max-width: 10vw;
  }
}

