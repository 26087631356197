.about-container {
    overflow-y: scroll;
    height: 100%;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
    overflow-x: hidden;
}

p {
    margin: 0;
}

.skills-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.lower-skills {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.skills-main div, .lower-skills div {
    padding: 2vh;
}
h4, h2, h1 {
    padding: 0 !important;
    margin: 0;
}
ul {
    padding-top: 0;
    margin-top: 1vh;
}

hr {
    border: 1px;
    height: 2px;
    background: #2c2b2b;
    background-image: linear-gradient(to right, #3e525e, #3f3f3f, #3e525e);
}

.edu-div, .emp-div {
    color: #c4eee8;
    /* color: #c4eee8; */
    /* color: rgb(0, 0, 0); */
    margin: 0.25em;
    margin-bottom: 1em;
}

.edu-div p, .emp-div p {
    padding: 0 !important;
    margin: 0;
    font-size: 10pt;
}

.loc-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

p i {
    font-size: 8pt;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media screen and (max-width: 600px) {
    .about-container {
        -webkit-animation: fadein 0.75s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.75s; /* Firefox < 16 */
            -ms-animation: fadein 0.75s; /* Internet Explorer */
            -o-animation: fadein 0.75s; /* Opera < 12.1 */
                animation: fadein 0.75s;
        }
    .skills-main {
        flex-direction: column;
    }
    .skills-main div {
        margin: 0;
        padding: 0;
    }
}