.contact-container {
    overflow-y: scroll;
    height: 100%;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: fit-content;
    font-size: 12pt;
    line-height: 14pt;
    padding: 0.75em;
    padding-top: 1vh;
    padding-bottom: 1vh;
}
.contact-info img {
    height: 14pt;
}
.contact-info p, a {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
    margin: 0;
    text-decoration: none;
    color: #c4eee8;
}

.contact-info a:hover {
    color: white;
}

h2 {
    padding-bottom: 2vh;
}

.email-me {
    padding: 2vh;
    padding-top: 4vh;
}

input[type=submit], input[type=reset] {
    background-color: #afb9c557;
    color: #99ffec;
    border: none;
    padding: 8px 16px;
    text-decoration: none;
    margin: 8px 2px;
    cursor: pointer;
    border-radius: 4px;
}

input[type=submit]:hover, input[type=reset]:hover {
    background-color: #555a5f;
    color: white;
	transition: all 0.25s ease-in-out;
}

.input-container{
	position:relative;
}
.message-container {
    height: fit-content;
}
.input-container p {
    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0;
}
.input-container input, textarea { 
    font-size: 14pt;
    color: #c4eee8;
    border:0;
    border-bottom: 1px solid #3f3f3f;  
    background:transparent;
    width:100%;
    resize: none;
    padding-top: 0.5em;
    padding-bottom: 0.;
}
.input-container input:focus, textarea:focus{ 
    border: 0;
    outline: none;
    border-bottom: 1px solid #c03728;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #3e525e inset !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #c4eee8 !important;
}

@media screen and (max-width: 600px) {
    .contact-container {
        -webkit-animation: fadein 0.75s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.75s; /* Firefox < 16 */
         -ms-animation: fadein 0.75s; /* Internet Explorer */
          -o-animation: fadein 0.75s; /* Opera < 12.1 */
             animation: fadein 0.75s;
    }
}